import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./i18n.js";
import { Spinner } from "react-bootstrap";
const App =  React.lazy(()=>import("./App"));

const LoadStyle = () => {
  return (
    <div className="loader-effect">
      <Spinner animation="grow" role="status" />
    </div>
  );
};

ReactDOM.render(
  
    <Suspense fallback={<LoadStyle />}>
      <App />
    </Suspense>
  ,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
